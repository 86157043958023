angular.module('fingerink')
    .factory('authInterceptor', ['$rootScope', '$q', 'session', '$translate', '$location','$state','swalService', function ($rootScope, $q, session, $translate, $location, $state, swalService) {
        var retries = 0,
            waitBetweenErrors = 100,
            maxRetries = 5;

        function onResponseError(httpConfig) {
            var $http = $injector.get('$http');
            setTimeout(function () {
                return $http(httpConfig);
            }, waitBetweenErrors);
        }

        return {
            request: function (config) {

                if (config.external) {
                    return config;
                }

                if (config.crossDomain === true) {
                    if (FingerinkEnvironmentAngular.disableApiVersion) {
                        config.url = FingerinkEnvironmentAngular.apiUrl + config.url;
                    } else {
                        config.url = FingerinkEnvironmentAngular.apiUrl + FingerinkVersion + '/' + config.url;
                    }
                }

                config.headers = config.headers || {};

                if (session.hasToken()) {
                    config.headers.Authorization = 'Bearer ' + session.get().token;
                }

                return config;
            },
            responseError: function (response) {
                if (response.status === 417) {
                    swalService.basicSwal('Algo ha ido mal',response.data.errorsHTTP, "error");
                } else if (response.status === 500) {
                    swalService.basicSwal('Algo ha ido mal', response.data, "error");
                } else if (response.status === 401) {
                    session.clear();
                    $state.go('public.login');
                }

                retries = 0;
                return $q.reject(response);
            }
        };
}]);
